

import { forwardRef } from "react";
import Router, { useRouter } from "next/router";

interface Props {
    showNav: boolean;
    children: React.ReactNode;
}

const SideBar = forwardRef<HTMLInputElement, Props>(({ showNav, children }: Props, ref) => {

    return (
        <div ref={ref} className="sidebar fixed w-58 h-full bg-white shadow-sm z-20">
            <div className="flex justify-center mt-6 mb-6">
                {/* <picture>
                    <img
                        className="h-auto cursor-pointer"
                        width={130} height={100}
                        src="/images/logo-quebrado.png"
                        alt="Indústria do Ingresso"
                        title="Ir para home"
                        onClick={() => {
                            Router.push("/");
                          }}
                    />
                </picture> */}
            </div>

            <div className="flex flex-col">
                {children}
            </div>
        </div>
    )
})


SideBar.displayName = "SideBar"

export default SideBar