
import { Fragment, FC } from "react"
import {
  Bars3CenterLeftIcon,
  PencilIcon,
  ChevronDownIcon,
  CreditCardIcon,
  Cog8ToothIcon,
  Bars3BottomLeftIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid"
import { BellIcon, CheckIcon, CogIcon } from "@heroicons/react/24/outline"
import { Menu, Transition, Popover } from "@headlessui/react"
import Link from "next/link"
import { signOut, useSession } from "next-auth/react"
import { GrUserAdmin } from "react-icons/gr"
import utils from "@/shared/utils"
import { GoDashboard } from "react-icons/go"
import RoleGuard from "@/shared/RoleGuard"
import RolesNames from "@/shared/RolesNames"
import { FaUserCog } from "react-icons/fa"

interface Props {
  showNav: boolean,
  setShowNav: any
}

export const TopBar: FC<Props> = ({ showNav, setShowNav }) => {
  const { data: session } = useSession()

  const sair = () => {
    utils.deleleCartCookie()
    utils.deleleQtdCart()
    signOut()
}

  const gerenciadorWidget = () => {
    return (session?.user.empresas ?? 0) > 0
      ? (
        <>
          <Menu.Item>
            <Link
              href="/gerenciador"
              className="flex hover:bg-red-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
            >
              <CogIcon className="h-4 w-4 mr-2" />
              Gerenciador
            </Link>
          </Menu.Item>
        </>
      )
      : (
        <>

          <div></div>
        </>
      )
  }

  return (
    <div
      className={`topbar z-10 shadow fixed w-full h-16 bg-white flex justify-between items-center transition-all duration-[400ms] ${showNav ? "pl-56" : ""
        }`}
    >
      <div className="pl-4 md:pl-16">
        <Bars3CenterLeftIcon
          className="h-8 w-8 text-gray-700 cursor-pointer"
          onClick={() => setShowNav(!showNav)}
        />
      </div>
      <div className="flex items-center pr-4 md:pr-16">
        <Popover className="relative">
          <Popover.Button className="outline-none mr-5 md:mr-8 cursor-pointer text-gray-700">
            <BellIcon className="h-6 w-6" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform scale-95"
            enterTo="transform scale-100"
            leave="transition ease-in duration=75"
            leaveFrom="transform scale-100"
            leaveTo="transform scale-95"
          >
            <Popover.Panel className="absolute -right-16 sm:right-4 z-50 mt-2 bg-white shadow-sm rounded max-w-xs sm:max-w-sm w-screen">
              <div className="relative p-3">
                <div className="flex justify-between items-center w-full">
                  <p className="text-gray-700 font-medium">Notifications</p>
                  <a className="text-sm text-red-500" href="#">
                    Mark all as read
                  </a>
                </div>
                <div className="mt-4 grid gap-4 grid-cols-1 overflow-hidden">
                  <div className="flex">
                    <div className="rounded-full shrink-0 bg-green-200 h-8 w-8 flex items-center justify-center">
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">
                        Notification Title
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Test Notification text for design
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="rounded-full shrink-0 bg-green-200 h-8 w-8 flex items-center justify-center">
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">
                        Notification Title
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Test Notification text for design
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="rounded-full shrink-0 bg-green-200 h-8 w-8 flex items-center justify-center">
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">
                        Notification Title
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Test Notification text for design
                      </p>
                    </div>
                  </div>
                  <div className="flex">
                    <div className="rounded-full shrink-0 bg-green-200 h-8 w-8 flex items-center justify-center">
                      <CheckIcon className="h-4 w-4 text-green-600" />
                    </div>
                    <div className="ml-4">
                      <p className="font-medium text-gray-700">
                        Notification Title
                      </p>
                      <p className="text-sm text-gray-500 truncate">
                        Test Notification text for design
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center items-center">
              {/* <picture>
                <img
                  src="https://randomuser.me/api/portraits/men/35.jpg"
                  className="rounded-full h-8 md:mr-4 border-2 border-white shadow-sm"
                  alt="profile picture"
                />
              </picture> */}
              <span className="hidden md:block font-medium text-gray-700">
                {utils.primeiroNome(session?.user?.name)}
              </span>
              <ChevronDownIcon className="ml-2 h-4 w-4 text-gray-700" />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform scale-95"
            enterTo="transform scale-100"
            leave="transition ease-in duration=75"
            leaveFrom="transform scale-100"
            leaveTo="transform scale-95"
          >
            <Menu.Items className="absolute right-0 w-56 z-50 mt-2 origin-top-right bg-white rounded shadow-sm">
              <div className="p-1">
                <Menu.Item>
                  <Link
                    href="/dashboard/pedidos"
                    className="flex hover:bg-red-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                  >
                    <GoDashboard className="h-4 w-4 mr-2" />
                    Dashboard
                  </Link>
                </Menu.Item>
                {RoleGuard.canRole(RolesNames.ADMIN, session?.user.roles) && (
                  <Menu.Item>
                    <Link
                      href="/admin"
                      className="flex hover:bg-red-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                    >
                      <GrUserAdmin className="h-4 w-4 mr-2" />
                      Administrativo
                    </Link>
                  </Menu.Item>
                )}
                {gerenciadorWidget()}
                <Menu.Item>
                  <Link
                    onClick={() => sair()}
                    href="javascript:;"
                    className="flex hover:bg-red-500 hover:text-white text-gray-700 rounded p-2 text-sm group transition-colors items-center"
                  >
                    <Cog8ToothIcon className="h-4 w-4 mr-2" />
                    Sair
                  </Link>
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  )
}